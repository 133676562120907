class criteriaForm {
    constructor(
        $scope,
        tenderService,
        loadExternalDataService,
        criteriaService,
        $timeout,
        ntFlash,
        uuid4,
        md5,
    ) {

        this.uuid4 = uuid4
        this.md5 = md5
        this.ntFlash = ntFlash
        this.$scope = $scope
        this.$timeout = $timeout
        this.tenderService = tenderService
        this.ExternalDataService =   loadExternalDataService;
        this.criteriaService =   criteriaService;
        this.AddedCriteria = false
        this.AddedLangCriteria = false
        console.log('load')
        console.log('load')

        
    }

    $onInit() {
        this.tender = this.mainTender
        if (!this.tender.awardCriteria && ['aboveThresholdUA','aboveThreshold', 'aboveThresholdEU', 'simple.defense'].includes(this.tender.procurementMethodType))
            this.tender.awardCriteria = 'lowestCost';

        this.requirementTitle = 'Юридична особа, яка є учасником процедури закупівлі (крім нерезидентів), має антикорупційну програму чи уповноваженого з реалізації антикорупційної програми, якщо вартість закупівлі товару (товарів), послуги (послуг) або робіт дорівнює чи перевищує 20 мільйонів гривень (у тому числі за лотом)';
        this.requirementDeletedStatus = false;

        this.loadCriteria()
        if(this.isPMT(['requestForProposal','belowThreshold', 'reporting', 'negotiation', 'negotiation.quick',  'simple.defense', 'reporting', 'closeFrameworkAgreementSelectionUA'])){
            this.AddedCriteria = true;
        }


        this.setRequirementDeletedStatus();

        this.$scope.$on('criteriaChanged', (event, data) => {
            console.log(event, data)

            this.$scope.$applyAsync(()=>{
                this.criteria = this.criteriaService.criteria;
                console.log(this.criteria)

            })
        // this.initEvent()
      });

    }

    loadCriteria(){
        if(this.criteriaService.getCriteria()  && this.criteriaService.getCriteria().length > 0){

            this.criteria = this.criteriaService.criteria
            console.log(this.criteria)
            this.AddedCriteria = true;
            const langCrit = this.criteria.find((el)=>el.classification.id === 'CRITERION.OTHER.BID.LANGUAGE')
            if(langCrit){
                this.AddedLangCriteria = true
            }
        }
    }


    // saveAllCriteria(create = false){
    //
    //     const criteria = this.criteria.map((el)=>{
    //         delete el.edit
    //         delete el.must_save
    //         el.requirementGroups = el.requirementGroups.map((group)=>{
    //
    //             group.requirements = group.requirements.map((requirement)=>{
    //
    //                 delete requirement.requirementForm
    //                 if(requirement.emptyTitle){
    //                     delete requirement.emptyTitle
    //                 }
    //                  if(requirement.minValue !== undefined){
    //                     delete requirement.minValue
    //                     delete requirement.maxValue
    //                 }
    //                 if(requirement.emptyTitle){
    //                     delete requirement.emptyTitle
    //                 }
    //                 if(requirement.dataType === 'number' || requirement.dataType === 'integer'){
    //                     requirement.expectedValue = Number(requirement.expectedValue)
    //
    //                 }
    //                 if( requirement.eligibleEvidences !== undefined){
    //                    requirement.eligibleEvidences = requirement.eligibleEvidences.map((eligibleEvidence)=>{
    //
    //                         delete eligibleEvidence.eligibleEvidenceForm
    //
    //                         return eligibleEvidence
    //                     })
    //
    //                 }
    //                 return requirement
    //             })
    //             return group
    //
    //         })
    //         return el
    //
    //     })
    //
    //
    //     this.criteriaService.saveCriterias(criteria, create)
    //         .then((response)=>{
    //               console.log(response)
    //         })
    // }

    //
    // removeCriteria(criteria){
    //     this.criteriaService.removeCriteria(criteria)
    //         .then((data)=>{
    //             console.log(data)
    //             this.criteria = this.criteria.filter((el)=>{
    //                 return el.id !== criteria.id
    //             })
    //             this.initEvent()
    //         })
    //
    // }

    addCriteria(){
        this.criteriaService.addCriterias()
            .then((data)=>{
                    let criteria = data.map( (el)=>{
                        // el.edit = el.classification.id === "CRITERION.EXCLUSION.BUSINESS.BANKRUPTCY"

                        return el
                    })
                // console.log(data)
                    this.criteriaService.setCriteria(criteria)
            })
            .then(()=>{
                this.loadCriteria()
                this.initEvent()
                this.AddedCriteria = true;
                this.addLangCriteria()
            })
            .catch(error => {
                console.log('criteriaService.addCriterias error', error);
            })
    }


    addArticle16Criteria(id){
        this.criteriaService.addArticle16Criteria(id)
    }


    addOtherCriteria(id){
        this.criteriaService.addOtherCriteria(id)
    }

    isServiceAndWorkshop(){
        return this.tender.mainProcurementCategory === "services" || this.tender.mainProcurementCategory === "works"
    }


    canAddArticle16() {
        return (
                this.tender.mainProcurementCategory ===  "services" ||
                this.tender.mainProcurementCategory === "works")
        || this.isPMT(
            [
                'aboveThreshold',
                'belowThreshold',
                'competitiveOrdering',
                'negotiation',
                'negotiation.quick',
                // 'reporting',
                'closeFrameworkAgreementSelectionUA'
            ])

    }


    addLocalOriginLevel(){
        this.criteriaService.addLocalOriginLevel()

    }

    addLangCriteria() {
        this.criteriaService.addLangCriterias()
            .then((criteria)=>{
                criteria[0].edit = false
                this.criteriaService.addCriteria(criteria)
            })
            .then(()=>{
                this.loadCriteria()
                this.initEvent()
                this.AddedLangCriteria = true;
            })
            .catch(error => {
                console.log('criteriaService.addCriterias error', error);
            })
    }

    addBidValidityPeriod() {
        this.criteriaService.addBidValidityPeriod()
    }




    deleteCriteria(index, classificationId){

        this.criteria.splice(index, 1);
        const isCriteriaPresents = this.criteria.some(el => el.classification.id === classificationId);
        if (!isCriteriaPresents) {
            switch (classificationId) {
                case 'CRITERION.OTHER.BID.GUARANTEE': this. criteriaService.setGuaranteeCriterias('CRITERION.OTHER.BID.GUARANTEE', false); break;
                case 'CRITERION.OTHER.CONTRACT.GUARANTEE': this. criteriaService.setGuaranteeCriterias('CRITERION.OTHER.CONTRACT.GUARANTEE', false); break;
                case 'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE': this. criteriaService.setGuaranteeCriterias('CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE', false); break;
                case 'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST': this. criteriaService.setGuaranteeCriterias('CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST', false); break;
                case 'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST': this. criteriaService.setGuaranteeCriterias('CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST', false); break;
                case 'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST': this. criteriaService.setGuaranteeCriterias('CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST', false); break;
            }
        }
        if (!this.criteria.length) delete this.criteria;
        this.initEvent()

    }

    addGuaranteeCriteria() {
        this.criteriaService.addGuaranteeCriteria()


    }

    addContractGuaranteeCriteria() {
        this.criteriaService.addContractGuaranteeCriteria()
    }

    addLccCriteria(id){
        // this.isMultilotTender = this.criteriaService.getMultilotTender()

        this.criteriaService.addLccCriteria(id)
            // .then(data => {
            //     const criteria = (data || []).find(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.' + id);
            //     if (criteria) {
            //         criteria.id = this.md5.createHash(this.uuid4.generate()),
            //             criteria.relatesTo = this.criteriaService.getSingleLot() ? 'tender': 'lot';
            //         if (this.isMultilotTender) criteria.relatedItem = '';
            //         if (!Array.isArray(this.criteria)) this.criteria = [];
            //
            //         criteria.current = JSON.parse(JSON.stringify(criteria));
            //         criteria.edit = true;
            //         this.criteria = [...this.criteria, criteria];
            //         this.criteriaService.setGuaranteeCriterias(id, true);
            //     }
            //     this.initEvent()
            // })
            // .catch(error => console.log('loadExternalDataService.getLccCriterias error', error));

    }





    isOptional(criteria){

        return this.criteriaService.isOptional(criteria)
    }

    isLcc(criteria) {
        return this.criteriaService.isLcc(criteria)
    }


    saveOptionalCriteria( criteria){

        let form = false
        console.log(criteria)
        criteria.requirementGroups.forEach((requirementGroup)=>{
            requirementGroup.requirements.forEach((requirement)=>{
                console.log(requirement)
                if(requirement.requirementForm && requirement.requirementForm.$invalid){

                    form = true

                }
                if(requirement.minValue !== undefined){
                    delete requirement.minValue
                    delete requirement.maxValue
                }
                if(requirement.emptyTitle){
                    delete requirement.emptyTitle
                }
                if(requirement.dataType === 'number' || requirement.dataType === 'integer'){
                    requirement.expectedValue = Number(requirement.expectedValue)

                }
            })

        })
        if(form){
            this.ntFlash.error('Заполните описание критерия')
            return;
        }

        if(criteria.must_save){
            this.criteriaService.saveOptionalCriteria(criteria)
                .then((data)=>{
                    criteria = data[0]
                    this.initEvent()
                })
                .catch((error)=>{
                    console.log('criteriaService.saveCriterias error', error);
                    let errors =  error.data.errors.map((el)=>{
                        return el.description
                    })
                    this.ntFlash.error(errors.join( ' '))
                });

        } else {
            let edit = true;
            if([ 'active.enquiries', 'active.tendering'].includes(this.tender.status)){
                edit = true;
            }else if([ 'draft', 'draft.pending', 'draft.stage2'].includes(this.tender.status)){
                edit = false;
            } else {
                this.ntFlash.error('Изменения в данном статусе запрещены');
                return;
            }



            this.criteriaService.saveOptionalCriteria(criteria, false, edit)
                .then((data)=>{
                    console.log(data);
                    this.initEvent()
                })
                .catch((error)=>{
                    this.ntFlash.error(error.data.errors[0].description)
                });


        }

    }

    setRequirementDeletedStatus() {
        const criteria = (this.criteria || []).find(el => el.classification.id === 'CRITERION.EXCLUSION.NATIONAL.OTHER');
        // if (criteria)
        //     this.requirementDeletedStatus = !criteria.requirementGroups[0].requirements.some(el => el.title === this.requirementTitle && el.status !== 'cancelled');
    }

    deleteRequirement(criteria, requirementGroup, requirement){
        console.log(criteria, requirementGroup, requirement)
        //todo - add check edit
        let edit = false
        if(['active.enquiries', 'active.tendering'].includes(this.tender.status)){
            edit = true
        }

        if(requirement.id === undefined){
            criteria.status = 'cancelled'
        } else {

            // canEditCriteria()

            this.criteriaService.deleteRequirement(criteria.id, requirementGroup.id, requirement.id, edit)
                .then((data) => {
                    console.log(data)
                    requirement.status = 'cancelled'
                })
        }
        this.initEvent()
    }

    restoreRequirement (criteria, requirementGroup, requirement) {

        // const requirementList = criteria.requirementGroups[0].requirements
        // let requirementCurrent = {}
        //
        // for (let i = requirementList.length - 1; i >= 0; i--) {
        //     console.log(requirementList[i].title)
        //
        //     if(requirementList[i].title === this.requirementTitle) {
        //     console.log(i);
        //         requirementCurrent = requirementList[i]
        //         break;
        //     }
        // }

        const req = {
            ...requirement,
            id: undefined,
            status: undefined
        }

        if(this.tenderInDraft()){

            this.criteriaService.restoreRequirement(criteria.id, requirementGroup.id, requirement.id, false)
                .then((data)=>{
                    console.log(data)
                    requirement.status = 'active'
                    console.log(requirement)
                })
        } else if(this.tenderInActive()){
            this.criteriaService.restoreRequirement(criteria.id, criteria.requirementGroups[0].id, requirementCurrent.id, true)
                .then((data)=>{
                    criteria.requirementGroups[0].requirements.push(data.data[0])
                    // this.requirementDeletedStatus = false;
                })

        } else {
            ntFlash.error('У цьому статусі Закупівлі зміни не можливі');
            return;
        }

        this.initEvent()
    }

    checkCriteria(id){
        return ['CRITERION.OTHER.CONTRACT.GUARANTEE','CRITERION.OTHER.BID.GUARANTEE'].includes(id)
    }

    isPMT(name) {
        return  name.includes( this.tender.procurementMethodType);
    }

    issetCriteria(id){
        return this.criteriaService.issetCriteria(id)
    }



    criteriaFor(criteria){
       return  this.isOptional(criteria) && this.criteriaService.getMultilotTender() && criteria.edit
    }

    getMultiLot(){
        return this.criteriaService.getMultilotTender()
    }

    getSingleLot(){
        return this.criteriaService.getSingleLot()
    }




    // editDraftEligibleEvidence( criteria_id, group_id, requirement_id,  eligibleEvidence){
    //
    //     let  eligible = {};
    //     angular.copy(eligibleEvidence, eligible)
    //
    //     setTimeout(()=>{
    //         delete eligible.actions
    //         this.criteriaService.editDraftEligibleEvidence(
    //             (eligible.id !== undefined) ? 'edit_draft' : 'create',
    //             criteria_id,
    //             group_id,
    //             requirement_id,
    //             eligible.id,
    //             eligible
    //         )
    //             .then((data)=>{
    //                 eligibleEvidence = data.data
    //                 this.ntFlash.success('Отредактировано');
    //                 this.initEvent()
    //             })
    //             .catch((error)=>{
    //                 console.log('criteriaForm editEligibleEvidence', error)
    //                 this.initEvent()
    //             })
    //
    //     },200)
    //
    // }

    addDraftEligibleEvidence (criteria_id, group_id, requirement_id, requirement, criteria) {

        if(criteria.must_save){
            this.ntFlash.error('Необходимо сначало сохранить критерий');
            return;
        }


        if(!this.tenderInDraft()){
            this.ntFlash.error('У цьому статусі Закупівлі не можливо видаляти Вимоги');
            return;
        }


        const newEligibleEvidence = {
            type: 'document',
            title: '',
            description: ''
        };
        this.criteriaService.editDraftEligibleEvidence(
            'create',
            criteria_id,
            group_id,
            requirement_id,
            null,
            newEligibleEvidence
        )
            .then((data)=>{
                if (!requirement.eligibleEvidences) requirement.eligibleEvidences = [];

                requirement.eligibleEvidences.push(data)

                console.log(data)
                this.ntFlash.success('Добавлено успешно');
                this.initEvent()
            })
            .catch((error)=>{
                console.log('criteriaForm addEligibleEvidence', error)
                this.initEvent()
            })
        console.log(requirement)

    }

    removeDraftEligibleEvidence (criteria_id, group_id, requirement_id, eligibleEvidence, index, requirement) {

        if (![ 'draft', 'draft.pending', 'draft.stage2'].includes(this.tender.status) && requirement.status) {
            ntFlash.error('У цьому статусі Закупівлі не можливо видаляти Вимоги');
            return;
        }

        this.criteriaService.editDraftEligibleEvidence(
            'delete',
            criteria_id,
            group_id,
            requirement_id,
            eligibleEvidence.id
        )
            .then((data)=>{


                if (requirement.eligibleEvidences.length > 0)
                    requirement.eligibleEvidences.splice(index, 1);
                if (requirement.eligibleEvidences.length === 0)
                    delete requirement.eligibleEvidences;


                this.ntFlash.success('Удалено успешно');
                this.initEvent()
            })
            .catch((error)=>{
                console.log('criteriaForm removeEligibleEvidence', error)
            })
    }


    editActiveEligibleEvidence( criteria_id, requirementGroup, requirements,  eligibleEvidence){
        eligibleEvidence.eligibleEvidenceForm.$setPristine()
        if(!this.tenderInActive()){
            this.ntFlash.error('У цьому статусі заборонено');
            return;
        }

        let  requirement = {};
        angular.copy(requirements, requirement)

        const evidences = requirement.eligibleEvidences.map((eligible)=>{
            return {
                title: eligible.title,
                type: eligible.type,
                description: eligible.description
            }
        })


        const data = {
            eligibleEvidences: evidences
        }

        this.criteriaService.editActiveEligibleEvidence(
            'create',
            criteria_id,
            requirementGroup.id,
            requirement,
            data
        )
            .then((data)=>{
                return data.data
            })
            .then((requirements)=>{
                let currentCriteria = this.criteria.find(el=> el.id === criteria_id)

                let currentGroup =   currentCriteria.requirementGroups.find(el=>el.id ===  requirementGroup.id )

                let archiveRequirement = {
                    dataType: "boolean",
                    dateModified: requirements[1].dateModified,
                    datePublished: requirements[1].dateModified,
                    eligibleEvidences: requirements[0].eligibleEvidences,
                    expectedValue: requirements[0].expectedValue,
                    id: requirements[1].id,
                    status: "cancelled",
                    title: requirements[0].title ,
                }

              currentGroup.requirements.splice( currentGroup.requirements.length - 1, 0, archiveRequirement )
            })
            .catch((error)=>{
                console.log('criteriaForm editEligibleEvidence', error)
                this.initEvent()
            })

    }

    addActiveEligibleEvidence (criteria_id, group_id, requirement_id, requirement, criteria) {

        if(criteria.must_save){
            this.ntFlash.error('Необходимо сначало сохранить критерий');
            return;
        }


        if(!this.tenderInActive()){
            ntFlash.error('У цьому статусі Закупівлі не можливо видаляти Вимоги');
            return;
        }


        const newEligibleEvidence = {
            type: 'document',
            title: '',
            description: ''
        };
        if (!requirement.eligibleEvidences) requirement.eligibleEvidences = [];

        requirement.eligibleEvidences.push(newEligibleEvidence)

    }

    removeActiveEligibleEvidence (criteria_id, group_id, requirement_id, eligibleEvidence, index, requirement) {

        if(!this.tenderInActive()){
            this.ntFlash.error('У цьому статусі заборонено');
            return;
        }


        if(eligibleEvidence.id){

            this.criteriaService.editActiveEligibleEvidence(
                'delete',
                criteria_id,
                group_id,
                requirement,
                null,
                eligibleEvidence.id
            )
                .then((data)=>{

                    if (requirement.eligibleEvidences.length > 0)
                        requirement.eligibleEvidences.splice(index, 1);
                    if (requirement.eligibleEvidences.length === 0)
                        delete requirement.eligibleEvidences;

                    this.ntFlash.success('Удалено успешно');
                    this.initEvent()
                })
                .catch((error)=>{
                    console.log('criteriaForm removeEligibleEvidence', error)
                })
        } else {

            if (requirement.eligibleEvidences.length > 0)
                requirement.eligibleEvidences.splice(index, 1);
            if (requirement.eligibleEvidences.length === 0)
                delete requirement.eligibleEvidences;

        }


    }

    canEditCriteria(){

        return [ 'draft', 'draft.pending', 'draft.stage2', 'active.enquiries', 'active.tendering'].includes(this.tender.status)
    }

    tenderInDraft(){
        return [ 'draft', 'draft.pending', 'draft.stage2'].includes(this.tender.status)
    }

    canAddLocalCriteria(){

        return this.isPMT([ 'aboveThresholdUA',
            'aboveThresholdUA',
            'competitiveDialogueEU',
            'aboveThresholdEU',
            'competitiveDialogueEU.stage2',
            'esco',
            'closeFrameworkAgreementUA',
            'aboveThreshold',
            'competitiveOrdering',
            'competitiveDialogueUA']) && this.canEditCriteria()
    }

    tenderInActive(){
        return [ 'active.enquiries', 'active.tendering'].includes(this.tender.status)
    }

    checkAboveThreshold(criteria){
        const approvedCriteria = [
            'CRITERION.OTHER.SUBJECT_OF_PROCUREMENT.LOCAL_ORIGIN_LEVEL',
            'CRITERION.OTHER.BID.GUARANTEE',
            'CRITERION.OTHER.CONTRACT.GUARANTEE',
            'CRITERION.EXCLUSION.NATIONAL.OTHER'
        ];

        // if(approvedCriteria.includes(criteria.classification.id)){
        //     return true
        // } else {
        //     return false
        // }
        return true
    }

    checkArticle16(criteria){
        const approvedCriteria = [
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
            'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING',
        ];

        if(this.canAddArticle16() && approvedCriteria.includes(criteria.classification.id)){
            return true
        } else {
            return false
        }
    }

    isLocalOriginLevel(criteria, requirement = null) {
        const localOriginCriteria = [
            'CRITERION.OTHER.SUBJECT_OF_PROCUREMENT.LOCAL_ORIGIN_LEVEL',
        ]

        return localOriginCriteria.includes(criteria.classification.id)
    }





    canAddEvidence(){

        return [ 'draft', 'draft.pending', 'draft.stage2'].includes(this.tender.status)
    }


    initEvent(){


        console.log(this.criteriaService.criteria)

        console.log(this.criteria)
        console.log(this.criteria)


        let data = {
            criteriaValid: true,
            criteriaPristine: false,
            criteriaDirty: true,

        }

        this.criteria.forEach((criteria)=>{
            criteria.requirementGroups.forEach((requirementGroup)=>{
                requirementGroup.requirements.forEach((requirement)=>{
                    if(requirement.requirementForm){
                        if(requirement.requirementForm.$invalid){
                            data.criteriaValid = false
                        }
                        if(!requirement.requirementForm.$pristine){
                            data.criteriaPristine = true
                        }
                        if(requirement.requirementForm.$dirty){
                            data.criteriaDirty = false
                        }
                    }

                    if(requirement.eligibleEvidences){
                        requirement.eligibleEvidences.forEach((eligibleEvidence)=>{

                            if(eligibleEvidence.eligibleEvidenceForm){
                                if(eligibleEvidence.eligibleEvidenceForm.$invalid){
                                    data.criteriaValid = false
                                }
                                if(!eligibleEvidence.eligibleEvidenceForm.$pristine){
                                    data.criteriaPristine = true
                                }
                                if(eligibleEvidence.eligibleEvidenceForm.$dirty){
                                    data.criteriaDirty = false
                                }
                            }


                        })
                    }


                })
            })
        })
        this.$scope.$emit('criteriaChanged', data)



    }

    checkScheduled(){
        if(this.tender.status || this.tender.status == 'scheduled'){
            return true
        } else {
            return false
        }

    }

    checkVisible(){
       return  this.isPMT(['reporting', 'negotiation', 'negotiation.quick',  'simple.defense', 'reporting', 'closeFrameworkAgreementSelectionUA'])
    }


}

    tenderView.component('criteriaForm', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/criteria-form.html',
    controller: criteriaForm,
    controllerAs: 'vm',
        bindings: {
            mainTender: '='
        },
});
